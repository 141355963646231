interface IBurgerMenuIcon {
  fill?: string;
}

const BurgerMenuIcon = (props: IBurgerMenuIcon) => {
  const { fill = '#F7C144' } = props;
  return (
    <svg
      width="34"
      height="19"
      viewBox="0 0 34 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.48485 1.94656H32.5152"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M1.48485 17.1945H32.5152"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BurgerMenuIcon;
